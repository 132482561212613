<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">List Karyakartas</div>
        <div class="
              col-lg-6 col-12
              text-end
              d-flex
              justify-content-end
              custom-flex-cloumn-mob
            "></div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-all-details-tab" data-bs-toggle="tab" data-bs-target="#voteralldetail"
                type="button" role="tab" aria-controls="vehicle-details" aria-selected="false"
                @click="redirecttokaryakartas">
                Karyakartas Details
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="voter-report-tab" data-bs-toggle="tab" data-bs-target="#voterreport"
                type="button" role="tab" aria-controls="customer-details" aria-selected="true">
                Reports
            </button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="voterreport" role="tabpanel" aria-labelledby="voter-report-tab">
            <div class="company-section-outer py-3" style="height: calc(100vh - 180px)">
                <div class="row g-3">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="feedback-chart-outer pb-3">
                            <div class="chart-label text-center">Designation wise Report</div>
                            <div v-if="checkEmptyDataDesignation">
                                <div style="position: relative;  min-height: 350px;">
                                    <div v-if="!showloadergenderreport">
                                        <Chart type="doughnut" :data="chartDataDesignation" :options="lightOptions"
                                            responsive="true" />
                                    </div>
                                    <div v-if="showloadergenderreport">
                                        <div class="custom-modal-spinner-loader">
                                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div style="position: relative;min-height: 350px;" class="d-flex align-items-center justify-content-center">
                                <div>
                                    No data found
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="feedback-chart-outer pb-3">
                            <div class="chart-label text-center">City wise Report</div>
                            <div v-if="checkEmptyDataCity">
                                <div style="position: relative; min-height: 350px;">
                                    <div v-if="!showloadergenderreport">
                                        <Chart type="doughnut" :data="chartDataCity" :options="lightOptions"
                                            responsive="true" />
                                    </div>
                                    <div v-if="showloadergenderreport">
                                        <div class="custom-modal-spinner-loader">
                                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div style="position: relative;min-height: 350px;" class="d-flex align-items-center justify-content-center">
                                <div>
                                    No data found
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="feedback-chart-outer pb-3">
                            <div class="chart-label text-center">Gender wise Report</div>
                            <div v-if="checkEmptyDataGender">
                                <div style="position: relative; min-height: 350px;">
                                    <div v-if="!showloadergenderreport">
                                        <Chart type="doughnut" :data="chartDataGender" :options="lightOptions"
                                            responsive="true" />
                                    </div>
                                    <div v-if="showloadergenderreport">
                                        <div class="custom-modal-spinner-loader">
                                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div style="position: relative;min-height: 350px;" class="d-flex align-items-center justify-content-center">
                                <div>
                                    No data found
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-4 col-md-4 col-12" v-if="checkEmptyDataDesignation">
                        <div class="card border-success shadow mb-2 bg-white rounded">
                            <div class="card-body">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" v-for="msg  in chartDesignationDataValue" :key="msg">
                                        <div class="d-flex justify-content-between"><span>{{ msg.ak84 ? msg.ak84 :
                                            "Not Recevied" }}</span><span class="fw-bold">{{ msg.count
    }}</span></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12" v-if="checkEmptyDataCity">
                        <div class="card border-success shadow mb-2 bg-white rounded">
                            <div class="card-body">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" v-for="msg  in chartCityDataValue" :key="msg">
                                        <div class="d-flex justify-content-between"><span>{{ msg.ak46 ? msg.ak46 :
                                            "Not Recevied" }}</span><span class="fw-bold">{{ msg.count
    }}</span></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12" v-if="checkEmptyDataGender">
                        <div class="card border-success shadow mb-2 bg-white rounded">
                            <div class="card-body">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" v-for="msg  in chartGenderDataValue" :key="msg">
                                        <div class="d-flex justify-content-between"><span>
                                            {{ msg.ak22 ? msg.ak22 :
                                            "Not Recevied" }}
                                            </span><span class="fw-bold">{{ msg.count
                                            }}</span></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="voteralldetail" role="tabpanel" aria-labelledby="voter-all-details-tab"></div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
export default {
    data() {
        return {
            chartDataDesignation: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            },
            chartDataCity: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            },
            chartDataGender: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            },
            lightOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: "#495057",
                        },
                    },
                },
            },
            chartGenderDataValue: [],
            chartDesignationDataValue: [],
            chartCityDataValue: [],
            showloadergenderreport: false,
            checkEmptyDataGender: false,
            checkEmptyDataCity: false,
            checkEmptyDataDesignation: false,
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.showloadergenderreport = true;
        this.getvoterreportschart();
        this.checkEmptyDataGender = true;
        this.checkEmptyDataCity = true;
        this.checkEmptyDataDesignation = true;
    },
    methods: {
        redirecttokaryakartas() {
            this.$router.push("/karyakartas/list");
        },
        getvoterreportschart() {
            this.ApiService.getkaryakartasreport().then((data) => {
                if (data.success == true) {
                    this.chartDataCity = {
                        labels: data.citylabels,
                        datasets: [
                            {
                                data: data.citycount,
                                backgroundColor: ["#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#808000",
                                    "#008000",
                                    "#008080",
                                    "#000080",
                                    "#808080",
                                    "#F08080",
                                    "#FF8C00",
                                    "#556B2F",],
                                hoverBackgroundColor: ["#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#808000",
                                    "#008000",
                                    "#008080",
                                    "#000080",
                                    "#808080",
                                    "#F08080",
                                    "#FF8C00",
                                    "#556B2F",],
                            },
                        ],
                    };
                    this.chartCityDataValue = data.citydata;
                    this.chartDataDesignation = {
                        labels: data.labels,
                        datasets: [
                            {
                                data: data.count,
                                backgroundColor: [
                                    "#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#808000",
                                    "#008000",
                                    "#008080",
                                    "#000080",
                                    "#808080",
                                    "#F08080",
                                    "#FF8C00",
                                    "#556B2F",
                                ],
                                hoverBackgroundColor: [
                                    "#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#808000",
                                    "#008000",
                                    "#008080",
                                    "#000080",
                                    "#808080",
                                    "#F08080",
                                    "#FF8C00",
                                    "#556B2F",
                                ],
                            },
                        ],
                    };
                    this.chartDesignationDataValue = data.designationdata;
                    this.chartDataGender = {
                        labels: data.genderlabels,
                        datasets: [
                            {
                                data: data.gendercount,
                                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                            },
                        ],
                    };
                    this.chartGenderDataValue = data.genderdata;
                    this.showloadergenderreport = false;
                }
                else {
                    this.showloadergenderreport = false;
                    this.checkEmptyDataGender = false;
                    this.checkEmptyDataCity = false;
                    this.checkEmptyDataDesignation = false;
                }
            });
        },
    },
};
</script>
<style scoped>
.company-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 520px; */
}

.company-section-outer::-webkit-scrollbar {
    display: block;
}

.company-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.feedback-chart-outer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
}

.feedback-chart-outer .chart-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    padding: 16px;
}
</style>